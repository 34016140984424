.portfolio-section {
    background: #fff;
    text-align: center;
  }
  
  .portfolio-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #04c2c9;
  }
  
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .portfolio-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .portfolio-item:hover img {
    transform: scale(1.1);
  }
  
  .portfolio-item h3 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
  }
 /* General Modal Styling */
/* General Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  text-align: center;
  position: relative;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Carousel */
.carousel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card {
  flex: 0 0 auto;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  height: auto;
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.carousel-btn {
  background: none;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.carousel-btn:hover {
  color: #007bff;
}

/* Links Styling */
.links {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add spacing between icons */
}

.links .icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s;
  cursor: pointer;
}

.links .icon:hover {
  transform: scale(1.1);
}
