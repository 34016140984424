/* Basic styles */
.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #284889; /* Dark theme matching Home page */
    font-family: 'Arial', sans-serif;
    color: #fff; /* Text color white */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative; /* To add a background animation */
  }
  
  /* Waving hand animation */
  .wave {
    display: inline-block;
    animation: wave-animation 2s infinite;
    transform-origin: 70% 70%;
  }
  
  @keyframes wave-animation {
    0% { transform: rotate(0deg); }
    15% { transform: rotate(15deg); }
    30% { transform: rotate(-10deg); }
    45% { transform: rotate(5deg); }
    60% { transform: rotate(-5deg); }
    75% { transform: rotate(2deg); }
    100% { transform: rotate(0deg); }
  }
  
  /* Title styling */
  .landing-page h1 {
    font-size: 3rem;
    margin: 0;
    font-weight: bold;
  }
  
  .landing-page h2 {
    font-size: 2rem;
    margin-top: 10px;
    color: #848c96; /* Light gray for contrast */
  }
  
  /* Hover effect to change background color */
  .landing-page:hover {
    background-color: #2d3748; /* Slightly darker shade on hover */
  }
  
  /* Background animation for a dynamic look */
  @keyframes gradientAnimation {
    0% {
      background: linear-gradient(135deg, #667eea, #764ba2);
    }
    50% {
      background: linear-gradient(135deg, #76b7b2, #ff8b8b);
    }
    100% {
      background: linear-gradient(135deg, #667eea, #764ba2);
    }
  }
  
  .landing-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* To keep the animation behind the content */
    background: linear-gradient(135deg, #667eea, #764ba2);
    animation: gradientAnimation 6s ease infinite; /* Smooth background animation */
  }
  
  /* Add floating effect to the elements */
  .landing-page h1, .landing-page h2 {
    animation: floatEffect 3s ease-in-out infinite;
  }
  
  @keyframes floatEffect {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  