.about-me {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    padding: 80px 20px;
  }
  
  .about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .profile-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-right: 40px;
    object-fit: cover;
    border: 5px solid #04c2c9;
  }
  
  .about-details {
    max-width: 600px;
  }
  
  .about-details h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-details p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: #04c2c9;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background: #036b75;
  }
  