.contact-section {
    background: #f9f9f9;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #04c2c9;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    height: 150px;
    resize: none;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background: #04c2c9;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .contact-form button:hover {
    background: #036b75;
  }
  