/* Reset Styles */
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  color: #333;
  line-height: 1.6;
}

/* Typography */
h1 {
  font-size: 2.5rem;
  color: #04c2c9;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #04c2c9;
  text-align: center;
}

p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 10px;
}

/* Links */
a {
  text-decoration: none;
  color: #04c2c9;
  transition: color 0.3s ease;
}

a:hover {
  color: #036b75;
}

/* Buttons */
button {
  padding: 10px 20px;
  background-color: #04c2c9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #036b75;
}

/* Utility Classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-center {
  text-align: center;
}
/* IndexPage.css */
.index-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.content h2 {
  font-size: 2rem;
  margin: 10px 0;
  color: #666;
}

/* Wave animation */
.wave {
  display: inline-block;
  animation: wave-animation 2s infinite;
}

@keyframes wave-animation {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
}
