.skills-section {
    background: #f0f8ff;
    text-align: center;
  }
  
  .skills-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #04c2c9;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .skill {
    width: 200px;
    text-align: left;
  }
  
  .skill h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .progress-bar {
    background: #ddd;
    border-radius: 20px;
    overflow: hidden;
    height: 10px;
  }
  
  .progress {
    background: #04c2c9;
    height: 100%;
    transition: width 0.4s ease;
  }
  